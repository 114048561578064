import { graphql } from "gatsby"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import slugify from "slugify"
import Seo from "../components/Seo"
const RecipeTemplate = ({ data }) => {
  const {
    name,
    tags: { tags },
    descrip: { descrip },
    image,
    featured,
  } = data.contentfulProduct
  const pathToImage = getImage(image)
  const nametoUp = name.split(" ")
  //loop through each element of the array and capitalize the first letter.
  for (var i = 0; i < nametoUp.length; i++) {
    nametoUp[i] = nametoUp[i].charAt(0).toUpperCase() + nametoUp[i].slice(1)
  }
  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  const nameUp = nametoUp.join(" ")
  return (
    <Layout>
      <Seo title={nameUp} description={descrip} />
      <main className="page">
        <div className="recipe-page">
          <section className="recipe-hero">
            <GatsbyImage image={pathToImage} alt={name} width={800} />
            <article className="recipe-info">
              <h2>{nameUp}</h2>
              <p>
                {descrip}
                {featured ? "" : " This article has been Sold."}
              </p>
              <p className="recipe-tags">
                Category :{" "}
                {tags.map((tag, index) => {
                  const slug = slugify(tag, { lower: true })

                  return (
                    <Link to={`/tags/${slug}`} key={index}>
                      {tag}
                    </Link>
                  )
                })}
              </p>
              <p>
                Contact us for more informations{" "}
                <form action="https://formspree.io/f/mqknvwnb" method="POST">
                  <div className="form-row">
                    <label htmlFor="name">nom</label>
                    <input type="text" name="name" id="name" />
                  </div>
                  <div className="form-row">
                    <label htmlFor="email">email</label>
                    <input type="text" name="email" id="email" />
                  </div>
                  <div className="form-row">
                    <label htmlFor="message">message</label>
                    <textarea
                      name="message"
                      placeholder="Do not forget to refer the article in your message. Thank you."
                      id="message"
                    ></textarea>
                  </div>
                  <button type="submit" className="btn block">
                    submit
                  </button>
                </form>
              </p>
            </article>
          </section>
          <section className="recipe-content"></section>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query getSingleProduct($name: String) {
    contentfulProduct(name: { eq: $name }) {
      name
      descrip {
        descrip
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
      tags {
        tags
      }
      featured
    }
  }
`

export default RecipeTemplate
